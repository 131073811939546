.toc {
  .toc-title {
    font-size: $toc-title-font-size;
    font-weight: bold;
    text-transform: uppercase;
  }

  .toc-content {
    font-size: $toc-content-font-size;

    ul {
      text-indent: -0.85rem;
      padding-left: 0.8rem;
      list-style: none;

      a:first-child::before {
        content: "‣";
        font-weight: bolder;
        margin-right: 0.5rem;
        color: $single-link-color;

        [theme="dark"] & {
          color: $single-link-color-dark;
        }
        [theme="black"] & {
          color: $single-link-color-black;
        }
      }

      ul {
        padding-left: 1.5rem;
      }
    }
  }

  ruby {
    background: $code-background-color;

    rt {
      color: $global-font-secondary-color;
    }

    [theme="dark"] & {
      background: $code-background-color-dark;

      rt {
        color: $global-font-secondary-color-dark;
      }
    }
    [theme="black"] & {
      background: $code-background-color-black;

      rt {
        color: $global-font-secondary-color-black;
      }
    }
  }
}

#toc-auto {
  display: block;
  position: absolute;
  padding: 0 0.8rem;
  // border-left: 4px solid $global-border-color;
  @include overflow-wrap(break-word);
  box-sizing: border-box;
  top: 10rem;
  left: 82%;
  width: 20%;

  [header-desktop="normal"] & {
    top: 5rem;
  }

  @include blur;

  [theme="dark"] & {
    border-left-color: $global-border-color-dark;
  }
  [theme="black"] & {
    border-left-color: $global-border-color-black;
  }

  .toc-title {
    margin: 0.8rem 0;
  }

  &[kept="true"] {
    display: block;
  }

  .toc-content {
    // &.always-active ul {
    //   display: block;
    // }

    //   > nav > ul {
    //     margin: 0.625rem 0;
    //   }

    //   ul {
    //     ul {
    //       display: none;
    //     }

    //     .has-active > ul {
    //       display: block;
    //     }
    //   }

    a.active {
      font-weight: bold;
      color: $single-link-color;

      [theme="dark"] & {
        color: $single-link-color-dark;
      }
      [theme="black"] & {
        color: $single-link-color-black;
      }

      &::before {
        color: $single-link-hover-color;

        [theme="dark"] & {
          color: $single-link-hover-color-dark;
        }
        [theme="black"] & {
          color: $single-link-hover-color-black;
        }
      }
    }
  }
}

#toc-static {
  display: none;
  margin: 0.8rem 0;

  &[kept="true"] {
    display: block;
  }

  .toc-title {
    display: flex;
    justify-content: space-between;
    line-height: 2em;
    padding: 0 0.75rem;
    background: darken($code-background-color, 6%);

    [theme="dark"] & {
      background: darken($code-background-color-dark, 6%);
    }
    [theme="black"] & {
      background: darken($code-background-color-black, 6%);
    }
  }

  .toc-content {
    background-color: $code-background-color;

    > nav > ul {
      margin: 0;
      padding: 0.4rem 1rem 0.4rem 1.8rem;
    }

    [theme="dark"] & {
      background-color: $code-background-color-dark;
    }
    [theme="black"] & {
      background-color: $code-background-color-black;
    }
  }

  &.open {
    .toc-title {
      background: darken($code-background-color, 3%);

      [theme="dark"] & {
        background: darken($code-background-color-dark, 3%);
      }
      [theme="black"] & {
        background: darken($code-background-color-black, 3%);
      }
    }
  }
}
